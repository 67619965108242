import React from "react";
const styles = { color: 'orange', fontSize: '30px' }

const Pagina404 = () => {
  return (
    <>
      <p style={styles} >Soy Pagina404</p>
    </>
  );
}

export default Pagina404;
