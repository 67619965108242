import React from "react";

const SinLayout = (props) => {
  const { children } = props;
  return (
    <>
     {children}
    </>
  );
};

export default SinLayout;
