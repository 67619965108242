import React from "react";

const GraciasPorRegistrarte = () => {
  return (
    <div>
      <h3>
        Gracias por registrate verifica tu cuenta en tu correo electrónico
      </h3>
    </div>
  );
};

export default GraciasPorRegistrarte;
